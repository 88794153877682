import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import moment from "moment"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroBanner from "../components/BlogBanner"
import FromBlog from "../components/FromBlog"
import Stack from "../sdk/contentstack"
import init from "../modules/realtime"

const Blog = props => {
  let [data, setData] = useState({ allContentstackBlogPost: null, allContentstackPage: null, contentstackHeader: null, contentstackFooter: null })
  let archived = [],
    blogList = []

    useEffect(async () => {
      const [indexPage, banner, header, footer] = await Promise.all([
        Stack.getEntry("blog_post", ["author", "related_post"]),
        Stack.getEntryByUrl("page", "/blog"),
        Stack.getEntry("header", "navigation_menu.page_reference"),
        Stack.getEntry("footer")
      ])

      setData({ allContentstackBlogPost: { nodes: indexPage }, allContentstackPage: { nodes: banner }, contentstackHeader: header[0], contentstackFooter: footer[0] })
      init(indexPage[0].uid)
    }, [])

  if (data.allContentstackBlogPost)
    data.allContentstackBlogPost.nodes.forEach(blogs => {
      if (blogs.is_archived) {
        archived.push(blogs)
      } else {
        blogList.push(blogs)
      }
    })

  return (
    <Layout data={data}>
      <SEO title="Blog" />
      <HeroBanner data={data} />
      <div className="blog-container">
        <div className="blog-column-left">
          {blogList.map((blog, index) => {
            return (
              <div className="blog-list" key={index}>
                {blog.featured_image && (
                  <Link to={blog.url}>
                    <img
                      alt="blog-img"
                      className="blog-list-img"
                      src={blog.featured_image.url}
                    />
                  </Link>
                )}
                <div className="blog-content">
                  {blog.title && (
                    <Link to={blog.url}>
                      <h3>{blog.title}</h3>
                    </Link>
                  )}
                  <p>
                    {moment(blog.date).format("ddd, MMM D YYYY")},{" "}
                    <strong>{blog.author && blog.author[0].title}</strong>
                  </p>
                  {blog.body ? (
                    <p>{ReactHtmlParser(blog.body.slice(0, 300))}</p>
                  ) : (
                    ""
                  )}
                  {blog.url ? (
                    <Link to={blog.url}>
                      <span>{"Read more -->"}</span>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className="blog-column-right">
        { archived && archived.length > 0 && <h2>Archived Blogs</h2> }
          <FromBlog data={archived} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    contentstackPage(title: { eq: "Blog" }) {
      title
      url
      uid
      seo {
        enable_search_indexing
        keywords
        meta_description
        meta_title
      }
      page_components {
        contact_details {
          address
          email
          phone
        }
        from_blog {
          title_h2
          featured_blogs {
            title
            uid
            url
            is_archived
            featured_image {
              title
              url
            }
            body
            author {
              title
              uid
              bio
            }
          }
          view_articles {
            title
            href
          }
        }
        hero_banner {
          banner_description
          banner_title
          bg_color
          call_to_action {
            title
            href
          }
        }
        our_team {
          title_h2
          description
          employees {
            name
            designation
            image {
              title
              url
            }
          }
        }
        section {
          title_h2
          description
          image {
            title
            url
          }
          image_alignment
          call_to_action {
            title
            href
          }
        }
        section_with_buckets {
          title_h2
          description
          buckets {
            title_h3
            description
            icon {
              title
              url
            }
            call_to_action {
              title
              href
            }
          }
        }
        section_with_cards {
          cards {
            title_h3
            description
            call_to_action {
              title
              href
            }
          }
        }
        widget {
          title_h2
          type
        }
      }
    }

    allContentstackBlogPost {
      nodes {
        url
        title
        uid
        author {
          title
        }
        related_post {
          title
          body
        }
        date
        featured_image {
          url
        }
        is_archived
        body
      }
    }
  }
`

export default Blog
